<template>
  <div class="container">
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
    <div class="title">
      <div class="logocontent">
        <div class="logimg"><img src="../../static/logo.png" alt=""></div>
        <div><h3>家校共育·中医药文化与劳动技能教育系统课程</h3></div>
      </div>
      <div class="logoutcontent">
        <a-button  size="small" style="align-items: center; border-radius: 0.75rem; font-size: 0.55rem;color:#ffffff;width:3.2rem;height:1.1rem;background: #ADADAD;font-family: font-family: Source Han Sans SC;" @click="logout" class="login-button">退出</a-button>
      </div>

    </div>
      </a-layout-header>
    </a-layout>
    <a-layout-content>
      <div class="tabbar">
        <ul>
          <li :class="activeindex==0?'xueqiliactive':'xueqili'"  @click="Tab(0)">第一学期</li>
          <li :class="activeindex==1?'xueqiliactive':'xueqili'" @click="Tab(1)">第二学期</li>
          <li :class="activeindex==2?'xueqiliactive':'xueqili'" @click="Tab(2)">第三学期</li>
          <li :class="activeindex==3?'xueqiliactive':'xueqili'" @click="Tab(3)">第四学期</li>
          <li :class="activeindex==4?'xueqiliactive':'xueqili'" @click="Tab(4)">幼儿启蒙</li>
        </ul>
      </div>

      <div v-show="activeindex==0"  class="xueqidl">
        <div
            v-infinite-scroll="load"
            class="demo-infinite-container"
            :infinite-scroll-disabled="false"
            :infinite-scroll-distance="10"
        >
        <a-list
            class="demo-loadmore-list"
            item-layout="horizontal"
        >
          <a-list-item v-for="(item) in diyixueqilist" :key="item.ID" >
            <div class="itemcontent">
              <div class="itemtitle">
                <p>第{{item.Episode}}讲</p>
                <p>{{item.Name}}</p>
              </div>
              <div class="jindutiao"><img :src="item.Src" /></div>
              <div class="guankan">
                <a-button style="margin-right: 0.9rem; background-color: #46B872; width: 6.94rem;height: 1.74rem;color: #FFFFFF;font-size: 0.74rem;border-radius: 0.9rem;" @click="play(item.Num)" size="small" >
                  视频播放
                </a-button>
              </div>
            </div>
          </a-list-item>

        </a-list>
        </div>
      </div>
      <div v-show="activeindex==1"  class="xueqidl">
        <div
            v-infinite-scroll="load"
            class="demo-infinite-container"
            :infinite-scroll-disabled="false"
            :infinite-scroll-distance="10"
        >
          <a-list
              class="demo-loadmore-list"

              item-layout="horizontal"

          >
            <a-list-item v-for="(item) in dierxueqilist"  :key="item.ID">
              <div class="itemcontent">
                <div class="itemtitle">
                  <p>第{{item.Episode}}讲</p>
                  <p>{{ item.Name }}</p>
                </div>
                <div class="jindutiao"><img :src="item.Src" /></div>
                <div class="guankan">
                  <a-button style="margin-right: 0.9rem; background-color: #46B872; width: 6.94rem;height: 1.74rem;color: #FFFFFF;font-size: 0.74rem;border-radius: 0.9rem;" @click="play(item.Num)" size="small" >
                    视频播放
                  </a-button>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div v-show="activeindex==2"  class="xueqidl">
        <div
            v-infinite-scroll="load"
            class="demo-infinite-container"
            :infinite-scroll-disabled="false"
            :infinite-scroll-distance="10"
        >
          <a-list
              class="demo-loadmore-list"

              item-layout="horizontal"

          >
            <a-list-item v-for="(item) in disanxueqilist" :key="item.ID" >
              <div class="itemcontent">
                <div class="itemtitle">
                  <p>第{{ item.Episode}}讲</p>
                  <p>{{ item.Name }}</p>
                </div>
                <div class="jindutiao"><img :src="item.Src" /></div>
                <div class="guankan">
                  <a-button style="margin-right: 0.9rem; background-color: #46B872; width: 6.94rem;height: 1.74rem;color: #FFFFFF;font-size: 0.74rem;border-radius: 0.9rem;" @click="play(item.Num)" size="small" >
                    视频播放
                  </a-button>
                </div>
              </div>
            </a-list-item>

          </a-list>
        </div>
      </div>
      <div v-show="activeindex==3"  class="xueqidl">
        <div
            v-infinite-scroll="load"
            class="demo-infinite-container"
            :infinite-scroll-disabled="false"
            :infinite-scroll-distance="10"
        >
          <a-list
              class="demo-loadmore-list"

              item-layout="horizontal"
          >
            <a-list-item v-for="(item) in disixueqiliist" :key="item.ID" >
              <div class="itemcontent">
                <div class="itemtitle">
                  <p>第{{item.Episode}}讲</p>
                  <p>{{ item.Name }}</p>
                </div>
                <div class="jindutiao"><img :src="item.Src" /></div>
                <div class="guankan">
                  <a-button style="margin-right: 0.9rem; background-color: #46B872; width: 6.94rem;height: 1.74rem;color: #FFFFFF;font-size: 0.74rem;border-radius: 0.9rem;" @click="play(item.Num)" size="small" >
                    视频播放
                  </a-button>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div v-show="activeindex==4"  class="xueqidl">
        <div
            v-infinite-scroll="load"
            class="demo-infinite-container"
            :infinite-scroll-disabled="false"
            :infinite-scroll-distance="10"
        >
          <a-list
              class="demo-loadmore-list"

              item-layout="horizontal"
          >
            <a-list-item v-for="(item) in mofangliist" :key="item.ID" >
              <div class="itemcontent">
                <div class="itemtitle">
                  <p>第{{item.Episode}}讲</p>
                  <p>{{ item.Name }}</p>
                </div>
                <div class="jindutiao"><img :src="item.Src" /></div>
                <div class="guankan">
                  <a-button style="margin-right: 0.9rem; background-color: #46B872; width: 6.94rem;height: 1.74rem;color: #FFFFFF;font-size: 0.74rem;border-radius: 0.9rem;" @click="play(item.Num)" size="small" >
                    视频播放
                  </a-button>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0;position: absolute;bottom: 0;width: 100%;">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>
</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
import {getItem} from "../../utils/storage";
export default {
  name: "classdirectory"
  ,
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {
      activeindex:0,
      diyixueqilist:[],
      dierxueqilist:[],
      disanxueqilist:[],
      disixueqiliist:[],
      mofangliist:[],
      video:null,
      Twoclass:[]

    }
  },
  mounted() {
    this.GetMedicineCulture();

    },
  methods:{
    logout(){
      this.$router.push('/newlogin');
    },
    Tab(vals){
      this.activeindex=vals;
    },
    play(num){
    this.$router.push({path:'/videoPlayer',query:{num:num}})
    },

    GetMedicineCulture(){
      let that=this;
      that.$axios.post("/api/Video/MedicineCulture",{UserID:getItem("UserID")}).then((res) => {
        console.log(res)
                    if (res.data.Message=="SUCCESS"){
                    
                      that.Twoclass=res.data.Data;
                                  
                      that.Twoclass.forEach(function(curent,index,arr){
                        if(curent.Name=='第一学期'){
                          that.$axios.post("/api/Video/List",{page:1,size:20,oneclassid:1,twoclassid:1,userid:getItem("UserID")}).then((res) => {
        if (res.data.Message=="SUCCESS"){
          that.diyixueqilist=res.data.Data.VideoList;
          console.log(that.diyixueqilist)

        } });
                        }else if(curent.Name=='第二学期'){
                          that.$axios.post("/api/Video/List",{page:1,size:20,oneclassid:1,twoclassid:2,userid:getItem("UserID")}).then((res) => {
        if (res.data.Message=="SUCCESS"){
          that.dierxueqilist=res.data.Data.VideoList;
          console.log(that.dierxueqilist)
        }
      });
                        }else if(curent.Name=='第三学期'){
                          that.$axios.post("/api/Video/List",{page:1,size:20,oneclassid:2,twoclassid:3,userid:getItem("UserID")}).then((res) => {
        if (res.data.Message=="SUCCESS"){
          that.disanxueqilist=res.data.Data.VideoList;
          console.log(that.dierxueqilist)
        } });
                        }else if(curent.Name=='第四学期'){
                          that.$axios.post("/api/Video/List",{page:1,size:20,oneclassid:2,twoclassid:4,userid:getItem("UserID")}).then((res) => {
        if (res.data.Message=="SUCCESS"){
          that.disixueqiliist=res.data.Data.VideoList;
          console.log(that.dierxueqilist)
        } });
                        }else if(curent.Name=='幼儿启蒙'){
							that.$axios.post("/api/Video/List",{page:1,size:20,oneclassid:3,twoclassid:5,userid:getItem("UserID")}).then((res) => {
							if (res.data.Message=="SUCCESS"){
							  that.disixueqiliist=res.data.Data.VideoList;
							  console.log(that.dierxueqilist)
							} });
						}
                      })                 
                    }
                });
       
   
    
    },
  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background:  linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);

  box-sizing: border-box;

}
.title{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .logocontent{
    display: flex;
    flex-direction: row;
    .logimg{
   
      height: 2.8rem;
 
      img{
        width: 100%;
        height: 100%;
      }
    }
    h3{
      padding-top: 0.1rem;
      margin-left: 0.6rem;
      color: #46B872;
      font-size: 0.77rem;
     align-items: center;
      vertical-align: center;

    }
  }
  .logoutcontent{

  }
}
.tabbar{
  width: 33.5rem;
  height: 3.2rem;

  margin-top: 0.75rem;
  margin-left: auto;
  margin-right: auto;

  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      cursor: pointer;
      list-style:none; float:left; text-decoration:none; display:block;
      width:5.6rem;padding:5px; margin-left: 0.82rem; position:relative;

    }
  }

}
.xueqiliactive{
margin-top: 0.5rem;
  background-color:#46B872;
  color:#ffffff;
  width: 5.6rem;
  height: 2.66rem;
  border-radius: 15px 15px 0 0 ;
  text-align: center;
  vertical-align: center;
  font-size: 0.9rem;
  vertical-align: center;
  text-align: center;
  line-height: 2rem;
}
.xueqili{
  margin-top: 0.9rem;
  background-color:#46B872;
  color:#ffffff;
  width:5.6rem;
  height: 2.3rem;
  opacity: 0.67;
  border-radius: 15px 15px 0 0 ;
  font-size: 27px;
  line-height: 50px;
  text-align: center;
  vertical-align: center;

}
.xueqidl{
  background-color: #ffffff;
  border-radius: 0.62rem;
  padding: 0 0.4rem;
  box-shadow: 0px 0px 18px 0px rgba(8, 68, 31, 0.26);
  width:37rem;

  margin:0 auto;
  margin-bottom: 0.9rem;
  border-radius:0.7rem;
  z-index:1;
.itemcontent{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .itemtitle{
  display: flex;
    flex-direction: row;
    width:15rem;
    p{
      margin-left: 0.9rem;
      color: #333333;
      font-size: 0.8rem;
     height: 0.9rem;
    padding-top: 0.2rem;
      font-family: SourceHanSansSC;
    }
  }
  .jindutiao{
    display: flex;
    width: 8rem;
    height: 0.9rem;
    margin: auto 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .guankan{
display: flex;

  }
}

  .demo-infinite-container {

    border-radius: 4px;
    overflow: auto;

    height: 18rem;
  }
}
</style>